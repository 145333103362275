import React from 'react';
import { LINK_CELEBRATIONS_EDIT } from '../../../constants/links';
import { TCelebration } from '../../../types';
import { ItemA, TItemAPropsBase } from '../../dumb/Item/A';


export const CelebrationsItem = React.memo((props: TItemAPropsBase<TCelebration>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_CELEBRATIONS_EDIT}
    >
      <div
        className='fx1 wmin0 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.title,
        }}
      />
      <div className='w(|min)100 ov tov wsNW ph5'>
        {data.isActive ? 'Активный' : 'Неактивный'}
      </div>
    </ItemA>
  );
});

