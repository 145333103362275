import React from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  LINK_PLACES,
  LINK_REGIONS,
  LINK_MAIN_BANNERS,
  LINK_INTERESTING_BANNERS,
  LINK_STORIES,
  LINK_CELEBRATIONS,
  LINK_CELEBRATION_EVENTS,
} from "../../../constants/links";
import { NavLink } from "../../../utils/router";
import { handleLogOut } from "../../../store/auth";
import { ButtonA } from "../../dumb/Button/A";


const LINKS = [
  {
    name: 'Регионы',
    link: LINK_REGIONS,
  },
  {
    name: 'Места',
    link: LINK_PLACES,
  },
  {
    name: 'Основные баннеры',
    link: LINK_MAIN_BANNERS,
  },
  {
    name: 'Баннеры интересное',
    link: LINK_INTERESTING_BANNERS,
  },
  {
    name: 'Сторис',
    link: LINK_STORIES,
  },
  {
    name: 'Празднования',
    link: LINK_CELEBRATIONS,
  },
  {
    name: 'Мероприятия',
    link: LINK_CELEBRATION_EVENTS,
  },
];


export const Navigation = React.memo((props) => {
  return (
    <div className="pv15 dF fxdC aiI jcSB sq">
      <div className="w">
        {LINKS.map((item) => {
          const {
            name,
          } = item;

          return (
            <NavLink
              key={name}
              component={ButtonA}
              href={item.link}
              activeAsParent
            >
              {name}
            </NavLink>
          );
        })}
      </div>
      <div className="fx1 hmin0"/>
      <ButtonA
        className="w"
        onClick={handleLogOut}
      >
        <div className="mr10">
          Выйти
        </div>
        <ExitToAppIcon/>
      </ButtonA>
    </div>
  );
});