import { Store } from "effector";
import { useUnit } from "effector-react";
import { isFunction } from "lodash";
import { useCallback, useEffect, useState } from "react";

export type TSetStateArg<T> = Partial<T> | ((v: T) => T);
export type TSetState<T> = (state: TSetStateArg<T>) => void;

export const getItemFromStoreById = <T extends {id: string}>(items: T[], id: string): T | undefined => {
  return items.find((v) => v.id === id);
};

export const useItemFromStoreById = <T extends {id: string}>(
  $items: Store<T[]>,
  id: string,
  defaultValueFn: () => T,
  mapFn?: (v: T) => any,
): [T, TSetState<T>] => {
  const items = useUnit($items);
  const [value, setValue] = useState(defaultValueFn);
  const setState = useCallback((state: Partial<T> | ((v: T) => T)) => {
    setValue((value) => {
      const v = isFunction(state) ? state(value) : {
        ...value,
        ...state,
      };
      // eslint-disable-next-line
      return mapFn ? mapFn(v) : v;
    });
  }, []);

  useEffect(() => {
    setValue((value) => {
      const v = items.find((v) => v.id === id) || value;
      return mapFn ? mapFn(v) : v;
    });
  }, [items, id]);

  // eslint-disable-next-line
  return [value, setState];
};