export const LINK_DASHBOARD = '/';
export const LINK_LOGIN = '/login';

export const LINK_REGIONS = '/regions';
export const LINK_REGIONS_ADD = '/regions/add';
export const LINK_REGIONS_EDIT = '/regions/edit/';

export const LINK_PLACES = '/places';
export const LINK_PLACES_ADD = '/places/add';
export const LINK_PLACES_EDIT = '/places/edit/';

export const LINK_MAIN_BANNERS = '/main-banners';
export const LINK_MAIN_BANNERS_ADD = '/main-banners/add';
export const LINK_MAIN_BANNERS_EDIT = '/main-banners/edit/';

export const LINK_INTERESTING_BANNERS = '/interesting-banners';
export const LINK_INTERESTING_BANNERS_ADD = '/interesting-banners/add';
export const LINK_INTERESTING_BANNERS_EDIT = '/interesting-banners/edit/';

export const LINK_STORIES = '/stories';
export const LINK_STORIES_ADD = '/stories/add';
export const LINK_STORIES_EDIT = '/stories/edit/';

export const LINK_CELEBRATIONS = '/celebrations';
export const LINK_CELEBRATIONS_ADD = '/celebrations/add';
export const LINK_CELEBRATIONS_EDIT = '/celebrations/edit/';

export const LINK_CELEBRATION_EVENTS = '/celebration-events';
export const LINK_CELEBRATION_EVENTS_ADD = '/celebration-events/add';
export const LINK_CELEBRATION_EVENTS_EDIT = '/celebration-events/edit/';

export const LINK_MAIN = LINK_REGIONS;
