import React, { useCallback, useEffect, useMemo, useState } from 'react';
import trim from 'lodash/trim';
import { TCelebration, TFile } from '../../../types';
import { useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { backLocation } from '../../../utils/router';
import { TRouteProps } from '../../../utils/routerProvider';
import { apiCelebrations } from '../../../api/apiCelebrations';
import { $celebrations, celebrationsAPI } from '../../../store/celebrations';
import { TextFieldA } from '../../dumb/TextField/A';
import { CheckboxA } from '../../dumb/Checkbox/A';
import { ButtonB } from '../../dumb/Button/B';
import { WrapperWithLoading } from '../../dumb/WrapperWithLoading';
import { HeaderA } from '../../smart/Header/A';
import { toObject } from '../../../utils/toObject';
import { LableA } from '../../dumb/Label/A';
import { SelectImageArea, TSelectImageValue } from '../../dumb/SelectImageArea';
import { PhotoSelectA } from '../../smart/PhotoSelectA';
import { apiImagesGetUrlById } from '../../../api/apiImages';


const AREA_FIELDS = [
  /*
  {
    id: 'celebrationImage',
    name: 'Превью основного изображения',
    width: 280,
    height: 320,
  },
  */
  {
    id: 'celebrationBgDesktop',
    name: 'Фоновое изображение для десктопа',
    width: 630,
    height: 660,
  },
  {
    id: 'celebrationBgMobile',
    name: 'Фоновое изображение для мобильных устройств',
    width: 390,
    height: 550,
  },
];

export const PageCelebrationsEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($celebrations, id, () => ({
    id,
    title: '',
    image: '',
    logoImage: '',
    description: '',
    vkStepUrl: '',
    vkMusicUrl: '',
    vkVideoUrl: '',
    isActive: false,
    selectedImageParams: {},
    showBannerBlock: false,
    showCelebrationEventsAfisha: false,
    showCelebrationEventsMap: false,
    showEmojiBlock: false,
    showUserBackgroundImagesBlock: false,
    bannerBlockLink: '',
    bannerBlockTitle: '',
    bannerBlockImage: '',
  }));

  const [loading, setLoading] = useState(false);

  const {
    title,
    logoImage,
    image,
    bannerBlockTitle,
    bannerBlockLink,
    bannerBlockImage,
    showBannerBlock,
    showCelebrationEventsAfisha,
    showCelebrationEventsMap,
    showEmojiBlock,
    showUserBackgroundImagesBlock,
  } = itemOfOrigin;

  console.log('itemOfOrigin', itemOfOrigin);

  const [clicked, setClicked] = useState(false);
  const invalidName = useMemo(() => !trim(title), [title]);
  const [logoImages, _setLogoImages] = useState<TFile[]>([]);

  const errorOfLogoImages = logoImages.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfLogoImagesExist = !logoImage;

  const [images, _setImages] = useState<TFile[]>([]);

  const errorOfImages = images.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfImagesExist = !image;

  const [imageParams, _setImageParams] = useState<TSelectImageValue>({
    areas: {},
    size: [1, 1],
  });

  const setImageParams = useCallback((selectedImageParams: any) => {
    _setImageParams(selectedImageParams);
    setItemOfOrigin((v) => {
      return {
        ...v,
        selectedImageParams: {
          ...toObject(v.selectedImageParams),
          ...selectedImageParams,
        },
      };
    });
  }, []);

  const invalidBannerBlockTitle = useMemo(() => !trim(bannerBlockTitle), [bannerBlockTitle]);

  const invalidBannerBlockLink = useMemo(() => !trim(bannerBlockLink), [bannerBlockLink]);

  const [bannerBlockImages, _setBannerBlockImages] = useState<TFile[]>([]);

  const errorOfBannerBlockImages = bannerBlockImages.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfBannerBlockImagesExist = !bannerBlockImage;


  const disabled = invalidName
    || errorOfLogoImages
    || errorOfLogoImagesExist
    || errorOfImages
    || errorOfImagesExist
    || errorOfBannerBlockImages
    || showBannerBlock && (errorOfBannerBlockImagesExist || invalidBannerBlockTitle || invalidBannerBlockLink);

  const handleChangeName = useCallback((e: any) => {
    setItemOfOrigin({
      title: e.target.value,
    });
  }, []);

  const handleChangeIsActive = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);

  const handleChangeVkStepUrl = useCallback((e: any) => {
    setItemOfOrigin({
      vkStepUrl: e.target.value,
    });
  }, []);

  const handleChangeVkMusicUrl = useCallback((e: any) => {
    setItemOfOrigin({
      vkMusicUrl: e.target.value,
    });
  }, []);

  const handleChangeVkVideoUrl = useCallback((e: any) => {
    setItemOfOrigin({
      vkVideoUrl: e.target.value,
    });
  }, []);

  const handleChangeDescription = useCallback((e: any) => {
    setItemOfOrigin({
      description: e.target.value,
    });
  }, []);

  const setLogoImages = useCallback((items: TFile[]) => {
    _setLogoImages(items);
    setItemOfOrigin({
      logoImage: items[0]?.id || '',
    });
  }, []);

  const setImages = useCallback((items: TFile[]) => {
    _setImages(items);
    setItemOfOrigin({
      image: items[0]?.id || '',
    });
  }, []);

  const setBannerBlockImages = useCallback((items: TFile[]) => {
    _setBannerBlockImages(items);
    setItemOfOrigin({
      bannerBlockImage: items[0]?.id || '',
    });
  }, []);

  const handleChangeShowBannerBlock = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        showBannerBlock: !v.showBannerBlock,
      };
    });
  }, []);

  const handleChangeBannerBlockTitle = useCallback((e: any) => {
    setItemOfOrigin({
      bannerBlockTitle: e.target.value,
    });
  }, []);

  const handleChangeBannerBlockLink = useCallback((e: any) => {
    setItemOfOrigin({
      bannerBlockLink: e.target.value,
    });
  }, []);

  const handleChangeShowCelebrationEventsAfisha = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        showCelebrationEventsAfisha: !v.showCelebrationEventsAfisha,
      };
    });
  }, []);

  const handleChangeShowCelebrationEventsMap = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        showCelebrationEventsMap: !v.showCelebrationEventsMap,
      };
    });
  }, []);

  const handleChangeShowEmojiBlock = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        showEmojiBlock: !v.showEmojiBlock,
      };
    });
  }, []);

  const handleChangeShowUserBackgroundImagesBlock = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        showUserBackgroundImagesBlock: !v.showUserBackgroundImagesBlock,
      };
    });
  }, []);


  const handleSubmit = useCallback(() => {
    setItemOfOrigin((data) => {
      if (disabled) {
        setClicked(true);
        return data;
      }
      
      setLoading(true);

      (async () => {
        const id = data.id;

        data = {
          ...data,
          title: trim(data.title),
          description: trim(data.description),
          vkStepUrl: trim(data.vkStepUrl),
          vkMusicUrl: trim(data.vkMusicUrl),
          vkVideoUrl: trim(data.vkVideoUrl),
        };

        const params = {
          ...data,
          selectedImageParams: JSON.stringify(data.selectedImageParams),
        };

        delete (data as any).location;

        if (id) {
          const {
            error,
          } = await apiCelebrations.change({
            id,
            params,
          });

          if (error) {
            throw error;
          }


          celebrationsAPI.edit({
            id,
            data,
          });

        } else {
          const {
            error,
            data: _data,
          } = await apiCelebrations.create({
            params,
          });

          if (error) {
            throw error;
          }

          if (_data) {
            data = {
              ..._data,
              selectedImageParams: toObject(data.selectedImageParams),
            };
          }
  
          celebrationsAPI.add(data);
        }

        backLocation();

      })().then(() => {
        setLoading(false);
      });

      return data;
    });

  }, [disabled]);

  const imageDataUrl = useMemo(() => apiImagesGetUrlById(image), [image]);

  useEffect(() => {
    id && apiCelebrations.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TCelebration;
      let selectedImageParams = toObject(data.selectedImageParams || '{}');
      selectedImageParams = {
        ...selectedImageParams,
        areas: selectedImageParams.areas || {},
        size: selectedImageParams.size || [1, 1],
      };
      
      setItemOfOrigin({
        ...data,
        selectedImageParams,
      });

      function setImage(imageId: string, setImageFn: (files: TFile[]) => any) {
        setImageFn((imageId ? [imageId] : []).map((id: string) => {
          const dataUrl = apiImagesGetUrlById(id);
          return {
            id,
            dataUrl,
            previewUrl: dataUrl,
          };
        }));
      }

      setImage(data.bannerBlockImage, _setBannerBlockImages);
      setImage(data.logoImage, _setLogoImages);
      setImage(data.image, _setImages);
      _setImageParams(selectedImageParams);
    });
  }, [id]);

  return (
    <div className='sq'>
      <HeaderA id={id}>
        {id ? 'Редактирование празднования:' : 'Новое празднование:'} {title}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <div>
          <CheckboxA
            label="Активный"
            checked={itemOfOrigin.isActive}
            onChange={handleChangeIsActive}
          />
        </div>
        <TextFieldA
          className='mt15'
          label="Название"
          value={title}
          onChange={handleChangeName}
          error={invalidName && clicked}
        />

        <TextFieldA
          className='mt10'
          label="Описание"
          value={itemOfOrigin.description}
          onChange={handleChangeDescription}
          multiline
          rows={4}
        />
        <TextFieldA
          className='mt10'
          label="vkStepUrl"
          value={itemOfOrigin.vkStepUrl}
          onChange={handleChangeVkStepUrl}
        />
        <TextFieldA
          className='mt10'
          label="vkMusicUrl"
          value={itemOfOrigin.vkMusicUrl}
          onChange={handleChangeVkMusicUrl}
        />
        <TextFieldA
          className='mt10'
          label="vkVideoUrl"
          value={itemOfOrigin.vkVideoUrl}
          onChange={handleChangeVkVideoUrl}
        />

        <LableA className='mt10'>
          Лого
        </LableA>
        <PhotoSelectA
          limit={1}
          value={logoImages}
          onChange={setLogoImages}
          error={errorOfLogoImages || (clicked && errorOfLogoImagesExist)}
        />

        <LableA className='mt10'>
          Изображение
        </LableA>
        <PhotoSelectA
          limit={1}
          value={images}
          onChange={setImages}
          error={errorOfImages || (clicked && errorOfImagesExist)}
        />

        <div className='mt20'>
          Область изображения для превью
        </div>
        <SelectImageArea
          className='mt10'
          imageUrl={imageDataUrl}
          value={imageParams}
          onChange={setImageParams}
          fields={AREA_FIELDS}
        />


        <div className='mt20'>
          Баннер
        </div>
        <div>
          <CheckboxA
            label="Показывать баннер"
            checked={showBannerBlock}
            onChange={handleChangeShowBannerBlock}
          />
        </div>
        <TextFieldA
          className='mt10'
          label="Заголовок баннера"
          value={bannerBlockTitle}
          onChange={handleChangeBannerBlockTitle}
          error={invalidBannerBlockTitle && showBannerBlock && clicked}
        />
        <TextFieldA
          className='mt10'
          label="Ссылка баннера"
          value={bannerBlockLink}
          onChange={handleChangeBannerBlockLink}
          error={invalidBannerBlockLink && showBannerBlock && clicked}
        />
        <LableA className='mt10'>
          Изображение баннера
        </LableA>
        <PhotoSelectA
          limit={1}
          value={bannerBlockImages}
          onChange={setBannerBlockImages}
          error={errorOfBannerBlockImages || (clicked && showBannerBlock && errorOfBannerBlockImagesExist)}
        />


        <div className='mt30 mb10'>
          Отображение блоков
        </div>
        <div className='usN'>
          <div>
            <CheckboxA
              label="Показывать афишу"
              checked={showCelebrationEventsAfisha}
              onChange={handleChangeShowCelebrationEventsAfisha}
            />
          </div>
          <div>
            <CheckboxA
              label="Показывать карту мероприятий"
              checked={showCelebrationEventsMap}
              disabled={!showCelebrationEventsAfisha}
              onChange={handleChangeShowCelebrationEventsMap}
            />
          </div>
          <div>
            <CheckboxA
              label="Показывать выбор эмодзи статусов"
              checked={showEmojiBlock}
              onChange={handleChangeShowEmojiBlock}
            />
          </div>
          <div>
            <CheckboxA
              label="Показывать выбор обложки пользовотеля"
              checked={showUserBackgroundImagesBlock}
              onChange={handleChangeShowUserBackgroundImagesBlock}
            />
          </div>
        </div>

        <ButtonB
          className='w200 mt30'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});