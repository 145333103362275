import React from 'react';
import { LINK_CELEBRATION_EVENTS_EDIT } from '../../../constants/links';
import { TCelebrationEvent } from '../../../types';
import { ItemA, TItemAPropsBase } from '../../dumb/Item/A';


export const CelebrationEventsItem = React.memo((props: TItemAPropsBase<TCelebrationEvent>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_CELEBRATION_EVENTS_EDIT}
    >
      <div
        className='w200 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.title,
        }}
      />
      <div className='fx1 wmin0 ov tov wsNW ph5'>
        {(data as any).celebrationName}
      </div>
      <div className='w100 hmax ov ph5 lh f13'>
        <div>
          {data.startDate.split('T')[0]}
        </div>
        <div className='mt5'>
          {data.endDate.split('T')[0]}
        </div>
      </div>
      <div className='w(|min)100 ov tov wsNW ph5'>
        {data.isActive ? 'Активный' : 'Неактивный'}
      </div>
    </ItemA>
  );
});

