import React, { useCallback, useMemo, useState } from 'react';
import { useUnit } from 'effector-react';
import { InputAdornment } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import { List, AutoSizer } from 'react-virtualized';
import { escapeRegExp } from 'lodash';
import { paramStore } from '../../utils/stores';
import { Link } from '../../utils/router';
import { useDebounce } from '../../utils/useDebounce';
import { pushCustomModalRemove } from '../../services/customModals/modals/pushCustomModalRemove';
import { ButtonC } from '../dumb/Button/C';
import { Loading } from '../dumb/Loading';
import { TextFieldA } from '../dumb/TextField/A';


export type TItemWithId = {
  id: string,
} & Record<string, any>;


const $q = paramStore('q', '');

const handleChangeSearchValue = (e: any) => {
  $q.setState(e.target.value);
};

export const PageListBaseB = React.memo((props: {
  items: TItemWithId[],
  onRemove: ((params: { ids: string[] }) => any),
  Item: React.ElementType<{
    data: any,
    selected?: boolean,
    onSelect?: (id: string) => any,
    onRemove?: (id: string) => any,
    style?: React.CSSProperties,
  }>,
  titleText: string,
  addLink: string,
  addText: string,
  loading: boolean,
}) => {
  const {
    onRemove,
    Item,
    items: itemsOfOrigin,
  } = props;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleToggleSelected = useCallback((id: string) => {
    setSelectedItems((items: string[]) => {
      items = [...items];
      const index = items.indexOf(id);
      index > -1 ? items.splice(index, 1) : items.push(id);
      return items;
    });
  }, [setSelectedItems]);

  const handleRemove = useCallback(() => {
    pushCustomModalRemove({
      onAccept: () => {
        setSelectedItems((selectedItems: string[]) => {
          onRemove({
            ids: selectedItems,
          });
          return [];
        });
      },
    });
  }, [setSelectedItems, onRemove]);
  
  const handleRemoveById = useCallback((id: string) => {
    pushCustomModalRemove({
      onAccept: () => {
        setSelectedItems((selectedItems: string[]) => {
          const index = selectedItems.indexOf(id);
          if (index > -1) {
            selectedItems = [...selectedItems];
            selectedItems.splice(index, 1);
          }

          onRemove({
            ids: [id],
          });
          return selectedItems;
        });
      },
    });
  }, [setSelectedItems, onRemove]);

  
  const searchValue = useUnit($q);
  const [searchValueOfDelayed] = useDebounce(searchValue, 1000);
  
  
  const items: TItemWithId[] = useMemo(() => {
    const searchValueOfTrimed = searchValueOfDelayed.trim().toLowerCase();
    const regexpName = new RegExp(escapeRegExp(searchValueOfTrimed), 'gim');

    const items = itemsOfOrigin.map((v) => {
      return {
        ...v,
        name: v.name || v.title || '',
      };
    });
    items.sort((a, b) => a.name.localeCompare(b.name));

    if (!searchValueOfTrimed) {
      return items;
    }
    
    const output: TItemWithId[] = [];

    items.forEach((item: TItemWithId) => {
      let index = 0;
      const idOfReplaced = item.id.replace(regexpName, (v: string) => {
        index++;
        return `<b>${v}</b>`;
      });
      const nameOfReplaced = item.name.replace(regexpName, (v: string) => {
        index++;
        return `<b>${v}</b>`;
      });

      index && output.push({
        ...item,
        idOfReplaced,
        nameOfReplaced,
      });
    });

    return output;
  }, [itemsOfOrigin, searchValueOfDelayed]);

  return (
    <div className='sq rlv wmin500'>
      <div className='h50 dF fxdR aiC jcSB pr10'>
        <div className='dF fxdR aiC jcS'>
          <div className='sq50 dF fxdR aiC jcC rlv'>
          {
            props.loading ? (
              <Loading className='sq26-i>.Loading__Inner'/>
            ) : (
              <ListAltIcon/>
            )
          }
          </div>
          <div className='f18 pr5'>
            {props.titleText}
          </div>
        </div>
        <div className='dF fxdR aiC jcS ph5>1'>
          <TextFieldA
            className='mt10>.MuiInputBase-root'
            label="Поиск"
            value={searchValue}
            onChange={handleChangeSearchValue}
            variant="standard"
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <Link
              component={ButtonC}
              href={props.addLink}
            >
              {props.addText}
            </Link>
          </div>
          <div>
            <ButtonC
              disabled={selectedItems.length < 1}
              onClick={handleRemove}
            >
              Удалить
            </ButtonC>
          </div>
        </div>
      </div>
      <div className='abs sh st50 sb bs bs b bt1 bcF.1 ov'>
        <AutoSizer
          className="sq-i"
          children={(props) => {
            return (
              <List
                height={props.height}
                width={props.width}
                rowCount={items.length}
                rowHeight={50}
                rowRenderer={(props) => {
                  const item = items[props.index];
                  const {
                    id,
                  } = item;
                  return (
                    <Item
                      key={props.key || id}
                      data={item}
                      selected={selectedItems.includes(id)}
                      onSelect={handleToggleSelected}
                      onRemove={handleRemoveById}
                      style={props.style}
                    />
                  );
                }}
                noRowsRenderer={() => (
                  <div className="dF fxdR aiC jcC w h100">
                    Список пуст
                  </div>
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
});