import React from 'react';
import { SelectBase, TSelectBaseProps } from './Base';


const SelectAItem = React.memo(({
  data,
}: {
  data: any,
}) => {
  return (
    <div className='dF fdR aiC jcFS gap10'>
      <img src={data.dataUrl} className='dB sq(|min)50 ofCover' alt=""/>
      <div>{data.id}</div>
    </div>
  );
});


export const SelectA = React.memo((props: TSelectBaseProps) => {
  return (
    <SelectBase
      {...props}
      ItemComponent={SelectAItem}
    />
  );
});