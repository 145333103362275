import React from 'react';
import { LINK_REGIONS_EDIT } from '../../../constants/links';
import { TRegion } from '../../../types';
import { ItemA, TItemAPropsBase } from '../../dumb/Item/A';


export const RegionsItem = React.memo((props: TItemAPropsBase<TRegion>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_REGIONS_EDIT}
    >
      <div
        className='wmin0 fx1 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.name,
        }}
      />
      <div className='w(|min)100 ov tov wsNW ph5'>
        {data.isActive ? 'Активный' : 'Неактивный'}
      </div>
    </ItemA>
  );
});
