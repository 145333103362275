import React, { useCallback, useEffect, useMemo, useState } from 'react';
import trim from 'lodash/trim';
import { useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { backLocation } from '../../../utils/router';
import { $regions, regionsAPI } from '../../../store/regions';
import { TextFieldA } from '../../dumb/TextField/A';
import { CheckboxA } from '../../dumb/Checkbox/A';
import { ButtonB } from '../../dumb/Button/B';
import { TRouteProps } from '../../../utils/routerProvider';
import { apiRegions } from '../../../api/apiRegions';
import { WrapperWithLoading } from '../../dumb/WrapperWithLoading';
import { PhotoSelectA } from '../../smart/PhotoSelectA';
import { TFile, TRegion } from '../../../types';
import { apiImagesGetUrlById } from '../../../api/apiImages';
import { HeaderA } from '../../smart/Header/A';
import { LableA } from '../../dumb/Label/A';


export const PageRegionsEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($regions, id, () => ({
    id,
    name: '',
    imageId: '',
    communityUrl: '',
    clipUrl: '',
    videoUrl: '',
    backgroundImage: '',
    previewVideoImage: '',
    mainDescription: '',
    isActive: false,
  }));

  const [loading, setLoading] = useState(false);

  const {
    name,
    imageId,
  } = itemOfOrigin;

  const invalidName = useMemo(() => !trim(name), [name]);
  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !imageId;


  const [previewVideoImages, _setPreviewVideoImages] = useState<TFile[]>([]);

  const errorOfPreviewVideoImages = previewVideoImages.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );


  const [backgroundImages, _setBackgroundImages] = useState<TFile[]>([]);

  const errorOfBackgroundImages = backgroundImages.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );


  useEffect(() => {
    id && apiRegions.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TRegion;

      setItemOfOrigin(data);
      setImage(data.imageId, _setPhotos);
      setImage(data.previewVideoImage, _setPreviewVideoImages);
      setImage(data.backgroundImage, _setBackgroundImages);


      function setImage(imageId: string, setImageFn: (v: TFile[]) => any) {
        setImageFn((imageId ? [imageId] : []).map((id: string) => {
          const dataUrl = apiImagesGetUrlById(id);
          return {
            id,
            dataUrl,
            previewUrl: dataUrl,
          };
        }));
      }
    });
  }, [id]);

  const disabled = invalidName
    || errorOfPhotos
    || errorOfPreviewVideoImages
    || errorOfBackgroundImages
    || errorOfPhotosExist;


  const handleChangeName = useCallback((e: any) => {
    setItemOfOrigin({
      name: e.target.value,
    });
  }, []);

  const handleChangeCommunityUrl = useCallback((e: any) => {
    setItemOfOrigin({
      communityUrl: e.target.value,
    });
  }, []);

  const handleChangeClipUrl = useCallback((e: any) => {
    setItemOfOrigin({
      clipUrl: e.target.value,
    });
  }, []);

  const handleChangeVideoUrl = useCallback((e: any) => {
    setItemOfOrigin({
      videoUrl: e.target.value,
    });
  }, []);

  const handleChangeMainDescription = useCallback((e: any) => {
    setItemOfOrigin({
      mainDescription: e.target.value,
    });
  }, []);

  const handleChangeIsActive = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);


  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    setItemOfOrigin({
      imageId: items[0]?.id || '',
    });
  }, []);

  const setPreviewVideoImages = useCallback((items: TFile[]) => {
    _setPreviewVideoImages(items);
    setItemOfOrigin({
      previewVideoImage: items[0]?.id || '',
    });
  }, []);

  const setBackgroundImages = useCallback((items: TFile[]) => {
    _setBackgroundImages(items);
    setItemOfOrigin({
      backgroundImage: items[0]?.id || '',
    });
  }, []);

  const handleSubmit = useCallback(() => {
    setItemOfOrigin((data) => {
      if (disabled) {
        setClicked(true);
        return data;
      }

      setLoading(true);
      (async () => {
        const id = data.id;

        data = {
          ...data,
          name: trim(data.name),
          communityUrl: trim(data.communityUrl),
          clipUrl: trim(data.clipUrl),
          videoUrl: trim(data.videoUrl),
          previewVideoImage: trim(data.previewVideoImage),
          backgroundImage: trim(data.backgroundImage),
          mainDescription: trim(data.mainDescription),
        };

        if (id) {
          const response = await apiRegions.change({
            id,
            params: data,
          });

          const {
            error,
          } = response;

          if (error) {
            throw error;
          }

          regionsAPI.edit({
            id,
            data,
          });

        } else {
          const response = await apiRegions.create({
            params: data,
          });

          const {
            error,
          } = response;

          if (error) {
            throw error;
          }

          data = response.data || data;

          regionsAPI.add(data);
          /*
          replaceLocation({
            path: LINK_REGIONS_EDIT + data.id,
          });
          */
        }

        backLocation();

      })().then(() => {
        setLoading(false);
      });
      
      return data;
    });
    
  }, [disabled]);

  
  return (
    <div className='sq'>
      <HeaderA id={id}>
        {id ? 'Редактирование региона:' : 'Новый регион:'} {name}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <CheckboxA
          label="Активный"
          checked={itemOfOrigin.isActive}
          onChange={handleChangeIsActive}
        />
        <PhotoSelectA
          className='mt10'
          error={clicked && errorOfPhotosExist}
          limit={1}
          value={photos}
          onChange={setPhotos}
        />
        <TextFieldA
          className='mt15'
          label="Название"
          value={name}
          onChange={handleChangeName}
          error={invalidName && clicked}
        />
        <TextFieldA
          className='mt10'
          label="communityUrl"
          value={itemOfOrigin.communityUrl}
          onChange={handleChangeCommunityUrl}
        />
        <TextFieldA
          className='mt10'
          label="Ссылка на короткое видео"
          value={itemOfOrigin.clipUrl}
          onChange={handleChangeClipUrl}
        />
        <TextFieldA
          className='mt10'
          label="videoUrl"
          value={itemOfOrigin.videoUrl}
          onChange={handleChangeVideoUrl}
        />

        <LableA className='mt10'>
          Превью для видео
        </LableA>
        <PhotoSelectA
          limit={1}
          value={previewVideoImages}
          onChange={setPreviewVideoImages}
        />

        <LableA className='mt10'>
          Фоновое изображение
        </LableA>
        <PhotoSelectA
          limit={1}
          value={backgroundImages}
          onChange={setBackgroundImages}
        />

        <TextFieldA
          className='mt10'
          label="Основное описание"
          value={itemOfOrigin.mainDescription}
          onChange={handleChangeMainDescription}
          rows={5}
          multiline
        />
        
        <ButtonB
          className='w200 mt15'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});