import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $celebrationEvents, celebrationEventsAPI } from '../../../store/celebrationEvents';
import { LINK_CELEBRATIONS_ADD, LINK_CELEBRATION_EVENTS_ADD, LINK_PLACES_ADD } from '../../../constants/links';
import type { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseB } from '../../smart/PageListBaseB';
import { CelebrationEventsItem } from './CelebrationEventsItem';
import { apiCelebrationEvents } from '../../../api/apiCelebrationEvents';
import { apiCelebrations } from '../../../api/apiCelebrations';
import { $celebrations, celebrationsAPI } from '../../../store/celebrations';


const handleRemove = async (params: {
  ids: string[],
}) => {
  await apiCelebrationEvents.remove(params)
  celebrationEventsAPI.remove(params);
};



export const PageCelebrationEventsList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const celebrations = useUnit($celebrations);
  const items = useUnit($celebrationEvents).map((celebrationEvent) => {
    const {
      celebrationId,
    } = celebrationEvent;

    return {
      ...celebrationEvent,
      celebrationName: celebrations.find((v) => v.id === celebrationId)?.title || ''
    };
  });

  useEffect(() => {
    setLoading(true);
    apiCelebrations.getList().then((response) => {
      celebrationsAPI.set(response?.data || []);
    });

    apiCelebrationEvents.getList().then((response) => {
      celebrationEventsAPI.set((response?.data || []).map((v) => {
        return {
          ...v,
          ...(v.location || {
            cityName: '',
            building: '',
            street: '',
            lat: '0' as any,
            lon: '0' as any,
          }),
        };
      }));
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <PageListBaseB
      titleText="Мероприятия"
      addLink={LINK_CELEBRATION_EVENTS_ADD}
      addText="Добавить мероприятие"
      items={items}
      Item={CelebrationEventsItem}
      onRemove={handleRemove}
      loading={loading}
    />
  );
});