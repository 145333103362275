import React from 'react';
import { LINK_STORIES_EDIT } from '../../../constants/links';
import { TStory } from '../../../types';
import { ItemA, TItemAPropsBase } from '../../dumb/Item/A';


export const StoriesItem = React.memo((props: TItemAPropsBase<TStory>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_STORIES_EDIT}
    >
      <div
        className='w200 ov hmax ov ph5 lh cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.title,
        }}
      />
      <div className='w80 hmax ov ph5 lh'>
        <div
          className='f11 mb5'
        >Позиция</div>
        <div>
          {data.position}
        </div>
      </div>
      <div className='fx1 wmin0 ov tov wsNW ph5'>
        {(data as any).regionName}
      </div>
      <div className='w(|min)100 ov tov wsNW ph5'>
        {data.isActive ? 'Активный' : 'Неактивный'}
      </div>
    </ItemA>
  );
});
